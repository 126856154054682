<template>
  <div class="filter-area">
    <div class="font-weight-bold text-h5 mb-4">Báo cáo tồn kho</div>
    <v-card class="overflow-auto pa-5" flat>
      <div class="">
        <div class="font-weight-bold mb-2">Kho</div>
        <tp-autocomplete
          v-model="selectedBranches"
          :items="branches"
          class="tp-filter-autocomplete"
          chips
          dense
          deletable-chips
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          multiple
          no-data-text="Không có dữ liệu"
          outlined
          placeholder="Chọn kho"
          single-line
          small-chips
        ></tp-autocomplete>
      </div>

      <div class="mt-5">
        <div class="font-weight-bold mb-2">Loại sản phẩm</div>
        <tp-autocomplete
          v-model="selectedProductsTypes"
          :items="productTypes"
          chips
          class="tp-filter-autocomplete"
          deletable-chips
          dense
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          multiple
          no-data-text="Không có dữ liệu"
          outlined
          placeholder="Chọn loại sản phẩm"
          single-line
          small-chips
        ></tp-autocomplete>
      </div>

      <div class="mt-5">
        <div class="font-weight-bold mb-2">Nguồn gốc</div>
        <tp-autocomplete
          v-model="selectedProductsSource"
          :items="source"
          chips
          class="tp-filter-autocomplete"
          deletable-chips
          dense
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          multiple
          no-data-text="Không có dữ liệu"
          outlined
          placeholder="Chọn nguồn gốc"
          single-line
          small-chips
        ></tp-autocomplete>
      </div>

      <div class="mt-5 mb-5">
        <div class="font-weight-bold mb-2">Thời gian</div>
        <tp-input-time-filter
          disable-all-time-input
          disable-future
          :type="dateFilter.type"
          :value="dateFilter.value"
          @change="updateDateFilter($event)"
        ></tp-input-time-filter>
      </div>
    </v-card>
  </div>
</template>

<script>
import { PRODUCT_SOURCES } from "@/modules/Goods/constants";
export default {
  props: {
    branchesFilter: {
      type: Array
    },
    dateFilter: {
      type: Object
    },
    productSourceFilter: {
      type: Array
    },
    productTypesFilter: {
      type: Array
    }
  },

  computed: {
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },

    productTypes() {
      return this.$store.getters["PRODUCT_TYPE/allProductTypes"];
    },

    selectedBranches: {
      get() {
        return this.branchesFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "branchesFilter",
          value: val
        });
      }
    },

    selectedProductsTypes: {
      get() {
        return this.productTypesFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "productTypesFilter",
          value: val
        });
      }
    },

    selectedProductsSource: {
      get() {
        return this.productSourceFilter;
      },
      set(val) {
        this.$emit("updateFilterProperty", {
          filterProperty: "productSourceFilter",
          value: val
        });
      }
    }
  },

  created() {
    this.$store.dispatch("BRANCH/getAllBranches");
    this.$store.dispatch("PRODUCT_TYPE/getAllProductTypes");
  },

  data() {
    return {
      source: PRODUCT_SOURCES
    };
  },

  methods: {
    updateDateFilter(val) {
      this.$emit("updateFilterProperty", {
        filterProperty: "dateFilter",
        value: val
      });
    },

    updateSearchKey(val) {
      this.$emit("updateSearchKey", val);
    }
  }
};
</script>
<style lang="scss" scoped>
.filter-area {
  width: 256px;
}
</style>
