<template>
  <tp-modal
    name="modal-inventory-product-option-summary"
    width="70%"
    max-width="960px"
  >
    <v-card>
      <v-card-title class="pb-4">
        <div class="font-weight-bold">
          Tổng hợp nhập xuất tồn kho mã hàng: {{ selectedReport.SKU }}
        </div>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-container class="grey lighten-3 pa-5" fluid>
        <div class="font-weight-bold mb-2">
          {{ dateRange }}
        </div>
        <v-card class="px-5 py-5 text-body-1" flat>
          <div>
            <v-data-table
              class="tp-table-scroll datatable px-3 py-2"
              calculate-widths
              disable-pagination
              no-data-text="Không có dữ liệu"
              no-results-text="Không tìm thấy kết quả phù hợp"
              :headers="headers"
              hide-default-footer
              loading-text="Đang tải dữ liệu"
              :items="inventoryReportGeneral"
              item-key="id"
            >
              <template v-slot:body="{ items }">
                <tbody v-if="items.length > 0">
                  <!-- Start: Items -->
                  <tr v-for="item in items" :key="item.id">
                    <td>{{ item.branch_name }}</td>
                    <td>{{ item.avg_created_price | formatCurrency }}</td>
                    <td>{{ item.avg_unit_sold_price | formatCurrency }}</td>
                    <td class="text-center">{{ item.amount_import }}</td>
                    <td>{{ item.value_import | formatCurrency }}</td>
                    <td class="text-center">{{ item.amount_export }}</td>
                    <td>
                      {{ item.value_export | formatCurrency }}
                    </td>
                    <td class="text-center">
                      {{ item.inventory_amount }}
                    </td>
                    <td>
                      {{ item.inventory_value | formatCurrency }}
                    </td>
                  </tr>
                  <!-- End: Items -->
                </tbody>

                <tbody v-else>
                  <tr class="v-data-table__empty-wrapper">
                    <td colspan="8">Không có dữ liệu</td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-container>
    </v-card>
  </tp-modal>
</template>

<script>
import { formatDateTime } from "@/core/utils";
import { HEADER_MODAL_INVENTORY_PRODUCT_OPTION_SUMMARY } from "@/modules/Reports/constants";

export default {
  props: {
    dateFilter: {},
    selectedReport: {
      type: Object
    }
  },

  computed: {
    dateRange() {
      return this.dateFilter.value
        ? `Từ ngày ${formatDateTime(
            this.dateFilter.value[0],
            "DD/MM/YYYY"
          )} đến ngày ${formatDateTime(this.dateFilter.value[1], "DD/MM/YYYY")}`
        : "Toàn thời gian";
    },

    inventoryReportGeneral() {
      return this.$store.getters["INVENTORY_REPORTS/inventoryReportGeneral"];
    }
  },

  data() {
    return {
      headers: HEADER_MODAL_INVENTORY_PRODUCT_OPTION_SUMMARY
    };
  },

  methods: {
    closeModal() {
      this.$modal.hide({
        name: "modal-inventory-product-option-summary"
      });
    },
    formatDateTime
  }
};
</script>

<style lang="scss" scoped>
.tab-item {
  min-height: 500px;
}
</style>
