<template>
  <div class="action mr-5 mb-4">
    <!-- Start: Action row -->
    <div class="action-row d-flex align-center justify-space-between">
      <div class="action-row--col flex-grow-1 pr-5">
        <tp-search-option
          :disabled="
            inventoryReportStatusRequest.value === 'loading-getInventoryReports'
          "
          loadingCursor
          :options="searchOptions"
          :value="searchKey"
          @search="updateSearchKey($event)"
        ></tp-search-option>
      </div>
      <div class="action-row--col">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-3"
              color="primary"
              dark
              depressed
              outlined
              v-bind="attrs"
              v-on="on"
              :loading="
                inventoryReportStatusRequest.value ===
                  'loading-exportReportFileDataProduct'
              "
            >
              <v-icon left>mdi-file-download-outline</v-icon>
              Xuất file data sản phẩm
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(site, index) in ARRAY_LIST_MODEL_SITES"
              :key="index"
              @click="$emit('exportReportFileDataProduct', site.id)"
            >
              {{ site.text }}
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn
          class="ml-3 rounded-lg"
          color="primary"
          dark
          depressed
          outlined
          @click="$refs.file.click()"
        >
          <v-icon left>mdi-file-upload-outline</v-icon>
          Nhập file cập nhật TTKD
        </v-btn>
        <v-btn
          class="ml-3 rounded-lg"
          color="primary"
          dark
          depressed
          :loading="
            inventoryReportStatusRequest.value === 'loading-exportReportFile'
          "
          outlined
          @click="$emit('exportReportFile')"
        >
          <v-icon left>mdi-file-download-outline</v-icon> Xuất file
        </v-btn>
        <input
          ref="file"
          accept=".xlsx, .xls"
          hidden
          type="file"
          @change="uploadExcelFile()"
        />
      </div>
    </div>
    <!-- End: Action row -->
    <!-- Start: Action row -->
    <div class="action-row d-flex align-center justify-space-between mt-4">
      <div class="action-row--col align-center">
        <!-- Start: Show filter dropdown -->
        <tp-btn-overflow
          btn-class="white rounded-lg"
          :items="showFilterList"
          :selected-value="selectedShowFilterId"
          @change="selectedShowFilterIndex = $event"
          @select="selectShowFilter($event)"
        ></tp-btn-overflow>
        <!-- End: Show filter dropdown -->
      </div>
      <div class="action-row--col d-flex align-center">
        <div class="text-body-2">
          {{ paginationInfo.from }} - {{ paginationInfo.to }} trong số
          {{ paginationInfo.itemTotal }}
        </div>
        <v-pagination
          v-model="curPage"
          color="primary"
          :length="paginationInfo.pageTotal"
          :total-visible="5"
        ></v-pagination>
      </div>
    </div>
    <!-- End: Action row -->
  </div>
</template>

<script>
import { ARRAY_LIST_MODEL_SITES } from "@/core/constant";
export default {
  props: {
    currentPage: {
      type: Number
    },
    searchKey: {
      type: String
    },
    selectedShowFilterId: {
      type: Number
    }
  },

  computed: {
    curPage: {
      get() {
        return this.currentPage;
      },
      set(val) {
        this.$emit("updateCurrentPage", val);
      }
    },

    inventoryReportStatusRequest() {
      return this.$store.getters["INVENTORY_REPORTS/statusRequest"];
    },

    paginationInfo() {
      return this.$store.getters["INVENTORY_REPORTS/paginationInfo"];
    }
  },

  data() {
    return {
      showFilterList: [
        { id: 10, text: "Hiển thị 10" },
        { id: 50, text: "Hiển thị 50" },
        { id: 100, text: "Hiển thị 100" }
      ],

      searchOptions: [
        {
          type: "search",
          label: "Tìm kiếm theo mã SKU"
        }
      ],

      ARRAY_LIST_MODEL_SITES
    };
  },

  methods: {
    selectShowFilter(val) {
      this.$emit("updateItemPerPage", val.id);
    },

    updateSearchKey(val) {
      this.$emit("updateSearchKey", val);
    },

    async uploadExcelFile() {
      const excelFile = this.$refs.file.files;
      const formData = new FormData();

      Array.from(excelFile).forEach(f => {
        formData.append("file", f);
      });

      await this.$store.dispatch(
        "INVENTORY_REPORTS/importUpdateFile",
        formData
      );

      if (
        this.inventoryReportStatusRequest.value === "success-importUpdateFile"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã nhập file thành công"
          }
        });
      } else if (
        this.inventoryReportStatusRequest.value === "error-importUpdateFile"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">Lỗi</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.action {
  &-row {
    &--col {
      display: flex;
    }
  }
}
</style>
