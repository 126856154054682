<template>
  <div class="pb-5">
    <v-data-table
      class="tp-table-scroll datatable px-3 py-2"
      calculate-widths
      disable-pagination
      no-data-text="Không có dữ liệu"
      no-results-text="Không tìm thấy kết quả phù hợp"
      :headers="headers"
      hide-default-footer
      :loading="
        inventoryReportsStatusRequest.value ===
          'loading-getInventoryReportByOptionId'
      "
      loading-text="Đang tải dữ liệu"
      :items="inventoryReport"
      item-key="code"
    >
      <template v-slot:body="{ items }">
        <tbody v-if="items.length > 0">
          <!-- Start: Items -->
          <tr v-for="item in items" :key="item.id">
            <td>{{ item.branch_name }}</td>
            <td>
              {{
                item.created_at
                  ? formatDateTime(item.created_at, "DD/MM/YYYY")
                  : ""
              }}
            </td>
            <td>
              {{
                item.created_at
                  ? formatDateTime(item.created_at, "DD/MM/YYYY")
                  : ""
              }}
            </td>
            <td>{{ item.code }}</td>
            <td>{{ item.explain }}</td>
            <td>{{ item.unit_price | formatCurrency }}</td>
            <td>
              {{ item.unit_sold_price | formatCurrency }}
            </td>
            <td class="text-center">{{ item.sl_nhap }}</td>
            <td>{{ item.gt_nhap | formatCurrency }}</td>
            <td class="text-center">{{ item.sl_xuat }}</td>
            <td>{{ item.gt_xuat | formatCurrency }}</td>
            <td class="text-center">{{ item.sl_ton }}</td>
            <td>{{ item.gt_ton | formatCurrency }}</td>
          </tr>
          <!-- End: Items -->
        </tbody>

        <tbody v-else>
          <tr class="v-data-table__empty-wrapper">
            <td colspan="8">Không có dữ liệu</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { formatDateTime } from "@/core/utils";
import { HEADER_MODAL_INVENTORY_PRODUCT_OPTION } from "@/modules/Reports/constants";

export default {
  computed: {
    inventoryReport() {
      return this.$store.getters["INVENTORY_REPORTS/inventoryReport"];
    },
    inventoryReportsStatusRequest() {
      return this.$store.getters["INVENTORY_REPORTS/statusRequest"];
    }
  },

  data() {
    return {
      headers: HEADER_MODAL_INVENTORY_PRODUCT_OPTION
    };
  },

  methods: {
    formatDateTime
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }
  .copied-label {
    cursor: pointer;
    display: inline-block;
  }
  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
}
.tp-table-scroll {
  max-height: calc(100vh - 56px - 68px - 94px);
  overflow: auto;
}
</style>
