<template>
  <tp-modal name="modal-inventory-product-option" fullscreen>
    <v-overlay
      v-show="
        inventoryReportGeneralRequest.value ===
          'loading-getInventoryReportGeneral'
      "
      opacity="0.5"
    >
      <v-progress-circular
        color="grey lighten-2"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-card class="text-body-1 grey lighten-3">
      <v-toolbar class="modal-toolbar" dark color="primary" height="56px">
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-toolbar-title class="text-h5 font-weight-bold pl-0 ml-1">
          Chi tiết nhập xuất tồn kho
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-toolbar-items>
          <v-btn
            dark
            text
            :loading="
              inventoryReportGeneralRequest.value ===
                'loading-exportInventoryReportDetailFile'
            "
            outlined
            @click="exportInventoryReportDetailFile"
          >
            <v-icon left>mdi-file-download-outline</v-icon> Xuất file
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <div class="px-5">
        <div class="text-h6 font-weight-bold py-5">
          {{ selectedReport.product_option_name }} - SKU:
          {{ selectedReport.SKU }}
        </div>
        <filter-area
          :date-filter="dateFilter"
          :branches-filter="branchesFilter"
          :selected-report="selectedReport"
          @updateBranchesFilter="updateBranchesFilter"
          @updateDateFilter="updateDateFilter"
        ></filter-area>

        <data-table-item></data-table-item>

        <modal-inventory-product-option-summary
          :date-filter="dateFilter"
          :selected-report="selectedReport"
        ></modal-inventory-product-option-summary>
      </div>
    </v-card>
  </tp-modal>
</template>

<script>
import DataTableItem from "./components/DataTableItem.vue";
import { exportExcelFile } from "@/core/composables";
import FilterArea from "./components/FilterArea.vue";
import ModalInventoryProductOptionSummary from "./components/modal/ModalInventoryProductOptionSummary.vue";

export default {
  components: {
    DataTableItem,
    ModalInventoryProductOptionSummary,
    FilterArea
  },

  props: {
    selectedReport: {
      type: Object
    }
  },

  computed: {
    inventoryReportGeneralRequest() {
      return this.$store.getters["INVENTORY_REPORTS/statusRequest"];
    },

    inventoryReport() {
      return this.$store.getters["INVENTORY_REPORTS/inventoryReport"];
    }
  },

  async created() {
    const route = this.$route;

    await this.getDateFilterFromRouteQuery(route.query);
  },

  data() {
    return {
      branchesFilter: [],
      dateFilter: {
        value: null,
        type: 2
      }
    };
  },

  methods: {
    async exportInventoryReportDetailFile() {
      const payload = {
        option_id: this.selectedReport.option_id,
        branch_id: this.branchesFilter,
        fromDate:
          this.dateFilter && this.dateFilter.value
            ? this.dateFilter.value[0]
            : null,
        toDate:
          this.dateFilter && this.dateFilter.value
            ? this.dateFilter.value[1]
            : null
      };

      await exportExcelFile(this, {
        storeName: "INVENTORY_REPORTS",
        payload,
        actionStoreName: "exportInventoryReportDetailFile",
        fileName: "bao-cao-chi-tiet-vat-tu-hang-hoa",
        isCheckExportTime: true
      });
    },

    async getInventoryReport() {
      const filterData = {
        option_id: this.selectedReport.option_id,
        fromDate:
          this.dateFilter && this.dateFilter.value
            ? this.dateFilter.value[0]
            : null,
        toDate:
          this.dateFilter && this.dateFilter.value
            ? this.dateFilter.value[1]
            : null,
        branch_id: this.branchesFilter
      };

      await this.$store.dispatch(
        "INVENTORY_REPORTS/getInventoryReportByOptionId",
        filterData
      );
    },

    async getDateFilterFromRouteQuery(query) {
      if (query.page) {
        this.dateFilter = query.date_from
          ? {
              value: [query.date_from, query.date_to],
              type: parseInt(query.date_type)
            }
          : { value: null, type: 1 };
      }
    },

    closeModal() {
      this.$modal.hide({ name: "modal-inventory-product-option" });
    },

    updateBranchesFilter(value) {
      this.branchesFilter = value;
      this.getInventoryReport();
    },

    updateDateFilter(value) {
      this.dateFilter = value;
      this.getInventoryReport();
    }
  },

  watch: {
    "$route.query"(val) {
      this.getDateFilterFromRouteQuery(val);
    },

    selectedReport() {
      this.branchesFilter = [];
      this.getInventoryReport();
    }
  }
};
</script>

<style lang="scss" scoped>
.col-scrollable {
  max-height: calc(100vh - 56px - 40px);
  overflow-y: scroll;
}
.modal-toolbar {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
}
</style>
