<template>
  <div class="d_flex">
    <!-- Start: Filter area -->
    <div class="px-5">
      <filter-area
        :branches-filter="branchesFilter"
        :date-filter="dateFilter"
        :product-source-filter="productSourceFilter"
        :product-types-filter="productTypesFilter"
        :selected-show-filter-id="itemPerPage"
        @updateFilterProperty="updateFilterProperty($event)"
        @updateDateFilter="updateDateFilter($event)"
      ></filter-area>
    </div>
    <!-- End: Filter area -->

    <!-- Start: Main area -->
    <div class="flex-grow-1 overflow-hidden">
      <!-- Start: Top actions -->
      <top-action
        :current-page="currentPage"
        :search-key="searchKey"
        :selected-show-filter-id="itemPerPage"
        @updateCurrentPage="updateCurrentPage($event)"
        @updateSearchKey="updateSearchKey($event)"
        @updateItemPerPage="updateItemPerPage($event)"
        @exportReportFile="exportReportFile"
        @exportReportFileDataProduct="exportReportFileDataProduct"
      ></top-action>
      <!-- End: Top actions -->

      <!-- Start: Data table -->
      <div class="mr-5">
        <data-table-item
          :branches-filter="branchesFilter"
          :date-filter="dateFilter"
        ></data-table-item>
      </div>
      <!-- End: Data table -->
    </div>
    <!-- End: Main area -->
  </div>
</template>
<script>
import DataTableItem from "./components/DataTableItem";
import DateTimeFunction from "@/core/utils/date-time";
import { exportExcelFile } from "@/core/composables";
import FilterArea from "./components/FilterArea";
import TopAction from "./components/TopAction";
import { updateFilterProperty } from "@/core/composables";

export default {
  components: {
    DataTableItem,
    FilterArea,
    TopAction
  },

  computed: {
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },

    firstDayOfMonth() {
      const today = new Date();
      const firstDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        1
      );

      return `${firstDayOfMonth.getFullYear()}-${firstDayOfMonth.getMonth() +
        1}-${
        firstDayOfMonth.getDate() < 10
          ? "0" + firstDayOfMonth.getDate()
          : firstDayOfMonth.getDate()
      }`;
    },

    productTypes() {
      return this.$store.getters["PRODUCT_TYPE/allProductTypes"];
    },

    today() {
      return DateTimeFunction.today("yyyy-mm-dd");
    }
  },

  async created() {
    await this.$store.dispatch("BRANCH/getAllBranches");
    this.dateFilter = {
      value: [this.firstDayOfMonth, this.today],
      type: 2
    };
    const route = this.$route;

    this.branchesFilter = [this.branches[0]?.id];
    await this.getInventoryReportFromRouteQuery(route.query);
  },

  data() {
    return {
      branchesFilter: [],
      currentPage: 1,
      dateFilter: {
        value: null,
        type: 2
      },
      itemPerPage: 10,
      productTypesFilter: [],
      productSourceFilter: [],
      searchKey: null
    };
  },

  watch: {
    "$route.query"(val) {
      this.getInventoryReportFromRouteQuery(val);
    }
  },

  methods: {
    async exportReportFile() {
      const payload = {
        branch_id: this.branchesFilter,
        nguon_hang: this.productSourceFilter,
        category_id: this.productTypesFilter,
        fromDate:
          this.dateFilter && this.dateFilter.value
            ? this.dateFilter.value[0]
            : null,
        toDate:
          this.dateFilter && this.dateFilter.value
            ? this.dateFilter.value[1]
            : null
      };
      await exportExcelFile(this, {
        storeName: "INVENTORY_REPORTS",
        payload,
        actionStoreName: "exportReportFile",
        fileName: "tong-hop-bao-cao-ton-kho",
        isCheckExportTime: true
      });
    },

    async exportReportFileDataProduct(site) {
      await exportExcelFile(this, {
        storeName: "INVENTORY_REPORTS",
        payload: { site },
        actionStoreName: "exportReportFileDataProduct",
        fileName: "tong-hop-bao-cao-ton-kho",
        isCheckExportTime: false
      });
    },

    async pushRouter() {
      await this.$router.push({
        name: "report_inventory",
        query: {
          category_id:
            this.productTypesFilter && this.productTypesFilter.length > 0
              ? this.productTypesFilter
              : undefined,
          branch_id:
            this.branchesFilter && this.branchesFilter.length > 0
              ? this.branchesFilter
              : undefined,
          pr_src:
            this.productSourceFilter && this.productSourceFilter !== "all"
              ? this.productSourceFilter
              : undefined,
          date_type:
            this.dateFilter.value &&
            this.dateFilter.value[0] &&
            this.dateFilter.type
              ? this.dateFilter.type
              : undefined,
          date_from:
            this.dateFilter && this.dateFilter.value
              ? this.dateFilter.value[0]
              : undefined,
          date_to:
            this.dateFilter && this.dateFilter.value
              ? this.dateFilter.value[1]
              : undefined,
          search:
            this.searchKey && this.searchKey.length > 0
              ? this.searchKey
              : undefined,
          limit: this.itemPerPage,
          page: this.currentPage
        }
      });
    },

    async getInventoryReportFromRouteQuery(query) {
      if (query.page) {
        // Set filtered brands
        this.branchesFilter =
          typeof query.branch_id === "string"
            ? [parseInt(query.branch_id)]
            : typeof query.branch_id === "object"
            ? query.branch_id.map(item => parseInt(item))
            : null;
        // Set current page
        this.currentPage = parseInt(query.page);

        // Set filter date
        this.dateFilter = query.date_from
          ? {
              value: [query.date_from, query.date_to],
              type: parseInt(query.date_type)
            }
          : { value: null, type: 1 };

        // Set item per page
        this.itemPerPage = parseInt(query.limit);

        // Set filtered product source
        this.productSourceFilter =
          typeof query.pr_src === "string"
            ? [parseInt(query.pr_src)]
            : typeof query.pr_src === "object"
            ? query.pr_src.map(item => parseInt(item))
            : null;

        // Set filtered product types
        this.productTypesFilter =
          typeof query.category_id === "string"
            ? [parseInt(query.category_id)]
            : typeof query.category_id === "object"
            ? query.category_id.map(item => parseInt(item))
            : null;

        // Set search key
        this.searchKey = query.search || null;

        // Get inventory reports
        await this.$store.dispatch("INVENTORY_REPORTS/getInventoryReports", {
          branch_id: this.branchesFilter,
          nguon_hang: this.productSourceFilter,
          category_id: this.productTypesFilter ? this.productTypesFilter : [],
          fromDate:
            this.dateFilter && this.dateFilter.value
              ? this.dateFilter.value[0]
              : null,
          toDate:
            this.dateFilter && this.dateFilter.value
              ? this.dateFilter.value[1]
              : null,
          search: this.searchKey,
          per_page: this.itemPerPage,
          cur_page: this.currentPage
        });
      } else {
        // Push router query
        await this.pushRouter();
      }
    },

    updateFilterProperty(data) {
      updateFilterProperty(this, data.filterProperty, data.value);
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateCurrentPage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateItemPerPage(num) {
      if (this.itemPerPage === num) return;
      this.itemPerPage = num;
      this.currentPage = 1;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateSearchKey(val) {
      this.searchKey = val.searchKey;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    }
  }
};
</script>
