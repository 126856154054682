<template>
  <div class="table-container">
    <v-data-table
      class="tp-table-scroll table-fixed-header-custom pl-3 py-2 flex-table"
      calculate-widths
      disable-pagination
      fixed-header
      :headers="headers"
      hide-default-footer
      item-key="option_id"
      :items="inventoryReports"
      :loading="inventoryReportRequest.value === 'loading-getInventoryReports'"
      loading-text="Đang tải dữ liệu"
      no-data-text="Không có dữ liệu"
      no-results-text="Không tìm thấy kết quả phù hợp"
    >
      <!-- Start: Body -->
      <template v-slot:body="{ items }">
        <tbody v-if="items.length > 0">
          <!-- Start: Total -->
          <tr class="yellow lighten-5">
            <td class="font-weight-bold">
              {{
                `${
                  inventoryReportsSummary.branch_name
                    ? inventoryReportsSummary.branch_name
                    : "Tổng hợp"
                } (${paginationInfo.itemTotal})`
              }}
            </td>
            <td></td>
            <td class="text-center" style="width:80px">
              {{ inventoryReportsSummary.opening_stock }}
            </td>
            <td>
              {{ inventoryReportsSummary.opening_value | formatCurrency }}
            </td>
            <td class="text-center">
              {{ inventoryReportsSummary.number_input_warehouses }}
            </td>
            <td>
              {{
                inventoryReportsSummary.value_input_warehouses | formatCurrency
              }}
            </td>
            <td class="text-center">
              {{ inventoryReportsSummary.number_output_warehouses }}
            </td>
            <td>
              {{
                inventoryReportsSummary.value_output_warehouses | formatCurrency
              }}
            </td>
            <td class="text-center" style="width:80px">
              {{ inventoryReportsSummary.closing_stock }}
            </td>
            <td>
              {{ inventoryReportsSummary.closing_value | formatCurrency }}
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <!-- Start: Total -->
          <!-- Start: Items -->
          <tr
            v-for="item in items"
            :key="item.id"
            @click="openModalInventoryProductOption(item)"
          >
            <td>{{ item.SKU }}</td>
            <td>{{ item.product_option_name }}</td>
            <td class="text-center" style="width:80px">
              {{ item.opening_stock }}
            </td>
            <td>{{ item.opening_value | formatCurrency }}</td>
            <td class="text-center">
              {{ item.number_input_warehouses }}
            </td>
            <td>{{ item.value_input_warehouses | formatCurrency }}</td>
            <td class="text-center">{{ item.number_output_warehouses }}</td>
            <td>{{ item.value_output_warehouses | formatCurrency }}</td>
            <td class="text-center" style="width:80px">
              {{ item.closing_stock }}
            </td>
            <td>{{ item.closing_value | formatCurrency }}</td>
            <td>{{ item.nguon_hang }}</td>
          </tr>
          <!-- End: Items -->
        </tbody>

        <tbody v-else>
          <tr class="v-data-table__empty-wrapper">
            <td colspan="8">Không có dữ liệu</td>
          </tr>
        </tbody>
      </template>
      <!-- End: Body -->
    </v-data-table>
    <modal-inventory-product-option
      :selected-report="selectedReport"
    ></modal-inventory-product-option>
  </div>
</template>

<script>
import ModalInventoryProductOption from "./modals/ModalInventoryProductOption";
import { HEADER_INVENTORY_REPORTS } from "@/modules/Reports/constants";

export default {
  components: {
    ModalInventoryProductOption
  },

  props: {
    branchesFilter: {
      type: Array
    },
    dateFilter: {
      type: Object
    }
  },

  computed: {
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },

    inventoryReports() {
      return this.$store.getters["INVENTORY_REPORTS/inventoryReports"];
    },

    inventoryReportRequest() {
      return this.$store.getters["INVENTORY_REPORTS/statusRequest"];
    },

    inventoryReportsSummary() {
      return this.$store.getters["INVENTORY_REPORTS/inventoryReportsSummary"];
    },

    paginationInfo() {
      return this.$store.getters["INVENTORY_REPORTS/paginationInfo"];
    }
  },

  data() {
    return {
      headers: HEADER_INVENTORY_REPORTS,
      selectedReport: {}
    };
  },

  methods: {
    async openModalInventoryProductOption(report) {
      this.$modal.show({ name: "modal-inventory-product-option" });
      this.selectedReport = report;
    }
  }
};
</script>

<style lang="scss" scoped>
.table-fixed-header-custom::v-deep {
  .v-data-table__wrapper {
    flex: 1;
  }
}
.table-container {
  display: flex;
  margin-top: 20px;
  flex-grow: 1;
  overflow: hidden;
}
.flex-table {
  display: flex;
  flex-grow: 1;
  width: 100%;
}
.tp-table-scroll {
  overflow-y: hidden;
}
</style>
