<template>
  <div>
    <div class="pb-5 w-fit-content flat">
      <div class="d-flex align-center">
        <div class="mr-5">
          <tp-autocomplete
            v-model="selectedBranches"
            class="filter-autocomplete-custom white rounded rounded-lg"
            chips
            deletable-chips
            hide-details
            hide-selected
            :items="branches"
            item-text="name"
            item-value="id"
            multiple
            no-data-text="Không có dữ liệu"
            placeholder="Chọn kho"
            solo
            small-chips
          ></tp-autocomplete>
        </div>
        <div class="mr-5">
          <div class="time-filter white rounded rounded-lg">
            <tp-input-time-filter
              disable-all-time-input
              disable-future
              row
              :type="dateFilter.type"
              :value="dateFilter.value"
              @change="updateDateFilter($event)"
            ></tp-input-time-filter>
          </div>
        </div>

        <div>
          <v-btn
            color="primary"
            text
            @click="openModalInventoryProductOptionSummary()"
            >Xem tổng hợp</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    branchesFilter: {
      type: Array
    },
    dateFilter: {
      type: Object
    },
    selectedReport: {
      type: Object
    }
  },

  computed: {
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },

    inventoryReportStatusRequest() {
      return this.$store.getters["INVENTORY_REPORTS/statusRequest"];
    },

    selectedBranches: {
      get() {
        return this.branchesFilter;
      },
      set(val) {
        this.$emit("updateBranchesFilter", val);
      }
    }
  },

  methods: {
    async openModalInventoryProductOptionSummary() {
      const filterData = {
        branch_id: this.branchesFilter,
        fromDate:
          this.dateFilter && this.dateFilter.value
            ? this.dateFilter.value[0]
            : null,
        toDate:
          this.dateFilter && this.dateFilter.value
            ? this.dateFilter.value[1]
            : null,
        option_id: this.selectedReport.option_id
      };

      await this.$store.dispatch(
        "INVENTORY_REPORTS/getInventoryReportGeneral",
        filterData
      );

      if (
        this.inventoryReportStatusRequest.value ===
        "success-getInventoryReportGeneral"
      ) {
        this.$modal.show({
          name: "modal-inventory-product-option-summary"
        });
      }
    },

    updateDateFilter(val) {
      this.$emit("updateDateFilter", val);
    }
  }
};
</script>
<style lang="scss" scoped>
.time-filter {
  padding: 14px;
}

.w-fit-content {
  width: fit-content;
}

.filter-autocomplete-custom::v-deep {
  .v-input__control {
    padding: 7px !important;
    .v-input__slot {
      box-shadow: none !important;
      fieldset {
        border: none;
      }
    }
  }
}
</style>
