export const HEADER_INVENTORY_REPORTS = [
  {
    text: "Mã sản phẩm",
    align: "start",
    sortable: false,
    value: "SKU"
  },
  {
    text: "Tên sản phẩm",
    align: "start",
    sortable: false,
    value: "product_option_name"
  },
  {
    text: "SL đầu kỳ",
    align: "start",
    sortable: false,
    value: "opening_stock"
  },
  {
    text: "Giá trị đầu kỳ",
    align: "start",
    sortable: false,
    value: "opening_value"
  },
  {
    text: "SL nhập kho",
    align: "center",
    sortable: false,
    value: "number_input_warehouses"
  },
  {
    text: "Giá trị nhập kho",
    align: "start",
    sortable: false,
    value: "value_input_warehouses"
  },
  {
    text: "SL xuất kho",
    align: "center",
    sortable: false,
    value: "number_output_warehouses"
  },
  {
    text: "Giá trị xuất kho",
    align: "start",
    sortable: false,
    value: "value_output_warehouses"
  },
  {
    text: "SL cuối kỳ",
    align: "center",
    sortable: false,
    value: "closing_stock"
  },
  {
    text: "Giá trị cuối kỳ",
    align: "start",
    sortable: false,
    value: "closing_value"
  },
  {
    text: "Nguồn gốc",
    align: "start",
    sortable: false,
    value: "nguon_hang"
  }
];

export const HEADER_MODAL_INVENTORY_PRODUCT_OPTION = [
  {
    text: "Kho",
    align: "start",
    sortable: false,
    value: "branch_name"
  },
  {
    text: "Ngày hoạch toán",
    align: "start",
    sortable: false,
    value: "created_at"
  },
  {
    text: "Ngày chứng từ",
    align: "start",
    sortable: false,
    value: "created_at"
  },
  {
    text: "Số chứng từ",
    align: "start",
    sortable: false,
    value: "code"
  },
  {
    text: "Diễn giải",
    align: "start",
    sortable: false,
    value: "explain"
  },
  {
    text: "Đơn giá",
    align: "start",
    sortable: false,
    value: "unit_price"
  },
  {
    text: "Đơn giá bán",
    align: "start",
    sortable: false,
    value: "unit_sold_price"
  },
  {
    text: "SL nhập",
    align: "center",
    sortable: false,
    value: "sl_nhap"
  },
  {
    text: "Giá trị nhập",
    align: "start",
    sortable: false,
    value: "gt_nhap"
  },
  {
    text: "SL xuất",
    align: "center",
    sortable: false,
    value: "sl_xuat"
  },
  {
    text: "Giá trị xuất",
    align: "start",
    sortable: false,
    value: "gt_xuat"
  },
  {
    text: "SL tồn",
    align: "center",
    sortable: false,
    value: "sl_ton"
  },
  {
    text: "Giá trị tồn",
    align: "start",
    sortable: false,
    value: "gt_ton"
  }
];

export const HEADER_MODAL_INVENTORY_PRODUCT_OPTION_SUMMARY = [
  {
    text: "Kho",
    align: "start",
    sortable: false,
    value: "branch_name"
  },
  {
    text: "Đơn giá",
    align: "start",
    sortable: false,
    value: "avg_created_price"
  },
  {
    text: "Đơn giá bán",
    align: "start",
    sortable: false,
    value: "avg_unit_sold_price"
  },
  {
    text: "SL nhập",
    align: "center",
    sortable: false,
    value: "amount_import"
  },
  {
    text: "Giá trị nhập",
    align: "start",
    sortable: false,
    value: "value_import"
  },
  {
    text: "SL xuất",
    align: "center",
    sortable: false,
    value: "amount_export"
  },
  {
    text: "Giá trị xuất",
    align: "start",
    sortable: false,
    value: "value_export"
  },
  {
    text: "SL tồn",
    align: "center",
    sortable: false,
    value: "value_export"
  },
  {
    text: "Giá trị tồn",
    align: "start",
    sortable: false,
    value: "value_export"
  }
];
